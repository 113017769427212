<template>
  <div class="social-links text-center">
    <v-subheader class="px-0 justify-center">Через социальные сети</v-subheader>
    <!-- <v-btn color="#24292e" :loading="loading" dark class="soc-link" @click="AuthProvider('github')"><v-icon left>$vuetify.icons.github</v-icon>Github</v-btn> -->
    <!-- <v-btn color="#1877f2" :loading="loading" dark class="soc-link" @click="AuthProvider('facebook')"><v-icon left>$vuetify.icons.facebook</v-icon>Facebook</v-btn> -->
    <v-btn color="#5181b8" :loading="loading" dark class="soc-link" @click="AuthProvider('vkontakte')"><v-icon left>$vuetify.icons.vk</v-icon>Vkontakte</v-btn>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading:false,
    };
  },
  methods: {
    AuthProvider(provider) {
      var self = this;
      this.loading=true;
      this.$auth
        .authenticate(provider)
        .then((response) => {
          self.SocialLogin(provider, response);
        });
    },

    SocialLogin(provider, response) {
      this.$store
        .dispatch("socialLogin", {
          provider: provider,
          response: response,
        })
        .then((response) => {
          // console.log(response);
          this.loading=false;
          if (response.data.next) {
            this.$router.push({
              name: "SocialDopRegister",
              params: {
                id: response.data.socId,
                token: response.data.socToken,
              },
              query: {
                return_url: this.$route.query.return_url,
              },
            });
          } else {

            if (this.$route.query.return_url) {
              this.$router.push(this.$route.query.return_url);
            } else {
              this.$router.push({ name: "Home" });
            }
          }

        })
        .catch(() => {
          this.loading=false;
          // console.log({ err: error });
        });
    },
  },
};
</script>