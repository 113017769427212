<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card :loading="loader" class>
          <v-form v-model="valid" ref="form">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Авторизация</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <!-- <social></social> -->
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="E-mail"
                    prepend-icon="$vuetify.icons.account"
                    :rules="requiredRules"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    prepend-icon="$vuetify.icons.lock"
                    :append-icon="
                      showPassword
                        ? '$vuetify.icons.eye'
                        : '$vuetify.icons.eye_off'
                    "
                    :type="showPassword ? 'text' : 'password'"
                    label="Пароль"
                    @click:append="showPassword = !showPassword"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                  <vue-recaptcha
                    class="mb-4"
                    ref="recaptcha"
                    @verify="onVerifyGC"
                    @expired="onExpiredGC"
                    :sitekey="$root.gcKey"
                    :loadRecaptchaScript="true"
                  ></vue-recaptcha>
                  <!-- <v-checkbox v-model="remember" label="Запомнить"></v-checkbox> -->

                  <v-btn dark color="primary" @click="submit">Войти</v-btn>
                </v-col>
                <v-col cols="12">
                  <!-- <a @click="showReset =! showReset">Забыли пароль?</a> -->
                  <router-link :to="{ name: 'ForgotPassword' }"
                    >Забыли пароль?</router-link
                  >
                  <router-link class="ml-2" :to="{ name: 'Register', query: {
                    return_url : $route.query.return_url
                  } }"
                    >Регистрация</router-link
                  >
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <social></social>
            </v-card-text>  
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import Social from "./Social.vue";

export default {
  components: {
    Social,
    VueRecaptcha,
  },
  data: () => ({
    valid: true,
    loading: false,
    showPassword: false,
    email: "",
    password: "",
    // remember: false,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    emailRules: [
      (v) => !!v || "Поле обязательно",
      (v) => /.+@.+\..+/.test(v) || "Не верно введен E-mail",
    ],
    captcha: "",
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onVerifyGC: function (response) {
      // console.log('Verify: ' + response)
      this.captcha = response;
    },
    onExpiredGC: function () {
      // console.log('Expired')
      this.captcha = "";
    },
    resetGC() {
      this.$refs.recaptcha.reset();
      this.captcha = "";
    },
    submit() {
      event.preventDefault();
      if (!this.validate() || !this.captcha) return;
      this.loading = true;
      this.$store
        .dispatch("retrieveToken", {
          email: this.email,
          password: this.password,
          captcha: this.captcha,
        })
        .then(() => {
          this.loading = false;
          this.resetGC();
          if (this.$route.query.return_url) {
            this.$router.push(this.$route.query.return_url);
          } else {
            this.$router.push({ name: "Home" });
          }
        })
        .catch(() => {
          this.resetGC();
          this.loading = false;
        });
    },

    clear() {
      this.email = "";
      this.password = "";
      this.resetGC();
    },
  },
  computed: {
    loader() {
      if (this.loading) {
        return "cyan ";
      }
      return false;
    },
  },
  // mounted(){
  //   console.log(this.$root.gcKey);
  // },
};
</script>
